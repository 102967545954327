import React from 'react'
import {Container, Grid} from "@mui/material";
import Text from "../components/data-display/text";
import {useTheme} from "@mui/system";
import InlineLink from "../components/data-display/inline-link";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { FiChevronRight } from 'react-icons/fi';

const CategoryPage = ({ pageContext: {sections, segmentSlug, categorySlug} }) => {

    const theme = useTheme();

    return (
        <div style={{ background: "white", minHeight: "100vh", borderBottom: "1px solid rgb(220,220,220)" }}>
            <div style={{ height: "100px" }}/>
            <Container maxWidth={'lg'} >
                <div>
                    <Breadcrumbs aria-label="breadcrumb" separator={<FiChevronRight size={12} color={"grey"}/>}>
                        <Text variant={'body2'}>
                            <InlineLink to="/support" style={{ color: "black" }}>
                                Support
                            </InlineLink>
                        </Text>
                        <Text variant={'body2'}>
                            <InlineLink to="/support" style={{ color: "black" }}>
                                {segmentSlug === 'taskers' ? "Taskers" : "Posters"}
                            </InlineLink>
                        </Text>
                        <Text variant={'body2'} style={{ opacity: "0.5" }}>{sections[0]?.edges[0]?.node?.category}</Text>
                    </Breadcrumbs>
                </div>
                <div style={{ height: "64px" }}/>
                <Text variant={'h4'} medium style={{ color: "black" }}>{sections[0]?.edges[0]?.node?.category}</Text>
                <div style={{ height: "64px" }}/>
                <Grid container spacing={6}>
                    {
                        sections.map((section) => {
                            return <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Text variant={'h6'} medium style={{ color: "black" }}>{section.fieldValue}</Text>
                                <div style={{ height: "14px" }}/>
                                {
                                    section.edges.map((questions) => {
                                        return <Text variant={'body1'} regular style={{ color: "black" }} key={questions.node.slug}>
                                            <InlineLink to={`/support/${segmentSlug}/${categorySlug}/${questions.node.sectionSlug}/${questions.node.slug}`} style={{ color: "black" }}>
                                                {questions.node.title}
                                            </InlineLink>
                                        </Text>
                                    })
                                }
                            </Grid>
                        })
                    }
                </Grid>
            </Container>
        </div>
    )
}

export default CategoryPage